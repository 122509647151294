import {
  faGoogle,
  faMicrosoft,
  faSlack,
  faApple,
} from "@fortawesome/free-brands-svg-icons";

export const openIDConnectIssuers = [
  {
    issuer: "https://accounts.google.com",
    name: "Google",
    icon: faGoogle,
  },
  {
    issuer: "https://login.microsoftonline.com/{tenantid}/v2.0",
    name: "Microsoft",
    icon: faMicrosoft,
  },
  {
    issuer: "https://slack.com",
    name: "Slack",
    icon: faSlack,
  },
  {
    issuer: "https://appleid.apple.com",
    name: "Apple",
    icon: faApple,
  },
];
