import React from "react";
import useLayoutEffect from "../../scripts/use-isomorphic-layout-effect";
import { useAsyncAbortable } from "react-async-hook";
import { captureException } from "@sentry/core";

export default function useBankID({ inviteToken }: { inviteToken?: string }) {
  const [t, setT] = React.useState(0);
  const asyncToken = useAsyncAbortable(
    (abort) => start({ abort, inviteToken }),
    [inviteToken],
  );

  const status = useAsyncAbortable(
    (abort) =>
      asyncToken.result?.token
        ? poll(abort, asyncToken.result?.token)
        : Promise.resolve(null),
    [asyncToken.result, t],
    {
      setLoading: (state) => ({ ...state, loading: true }),
      setResult: (result, state) => ({
        ...state,
        result: {
          ...state.result,
          ...result,
        },
      }),
      onError: (error) => captureException(error),
    },
  );

  useLayoutEffect(() => {
    if (asyncToken.result) {
      const startTime = Date.now();
      const id = setInterval(
        () => setT(Math.trunc((Date.now() - startTime) / 1000)),
        500,
      );
      return () => clearInterval(id);
    }
  }, [asyncToken.result]);

  const message = asyncToken.result?.message || status.result?.message;
  let error = asyncToken.result?.error || status.result?.error;

  if (asyncToken.error || status.error) {
    if (!error) error = "bankid-rfa22";
  }
  let qrCode = asyncToken.result?.qrCode;
  if (status.result) qrCode = status.result.qrCode;

  if (status.result?.expired) asyncToken.result = undefined;

  return {
    qrCode,
    loading: asyncToken.loading || Boolean(asyncToken.result?.token),
    launchUrl:
      asyncToken.result?.autoStartToken &&
      launchUrl(asyncToken.result.autoStartToken),
    message,
    error,
    loginResult: status.result?.loginResult,
    expired: status.result?.expired,
    reset() {
      asyncToken.reset();
      status.reset();
      asyncToken.execute();
    },
  };
}

function launchUrl(autoStartToken: string) {
  const url = new URL(
    isAndroidOrIOS() ? "https://app.bankid.com" : "bankid:///",
  );
  url.searchParams.set("autostarttoken", autoStartToken);
  url.searchParams.set("redirect", "null");
  return url.toString();
}

function isAndroidOrIOS() {
  const devs = ["android", "ipad", "iphone", "ipod"];
  const re = new RegExp(devs.map((name) => `\\b${name}\\b`).join("|"), "i");
  return re.test(navigator?.userAgent);
}

function hintCodeToRFA(status: string, hintCode: string) {
  const rfa = {
    message: "",
    error: "",
    expired: false,
  };
  if (status === "pending") {
    switch (hintCode) {
      case "outstandingTransaction":
        rfa.message = "rfa1";
        break;
      case "noClient":
        rfa.message = "rfa1";
        break;
      case "started":
        rfa.message = "rfa15b";
        break;
      case "userSign":
        rfa.message = "rfa9";
        break;
      default:
        rfa.message = "rfa21";
        break;
    }
  } else {
    switch (hintCode) {
      case "expiredTransaction":
        rfa.message = "rfa8";
        break;
      case "certificateErr":
        rfa.message = "rfa16";
        break;
      case "userCancel":
        rfa.message = "rfa6";
        break;
      case "cancelled":
        rfa.message = "rfa3";
        break;
      case "startFailed":
        rfa.message = "expired";
        rfa.expired = true;
        break;
      default:
        rfa.error = "rfa22";
        break;
    }
  }
  return rfa;
}

async function start({
  abort,
  inviteToken,
}: {
  abort: AbortSignal;
  inviteToken?: string;
}): Promise<{
  error?: string;
  message?: string;
  token?: string;
  qrCode?: string;
  autoStartToken?: string;
}> {
  const body = new URLSearchParams();
  if (inviteToken) body.append("inviteToken", inviteToken);
  const res = await fetch("/bankid/start", {
    method: "POST",
    signal: abort,
    body,
  });
  if (res.status == 200) {
    const data = await res.json();
    const orderRef = data.orderRef;
    const autoStartToken = data.autoStartToken;
    const qrAuthCode = data.qrAuthCode;
    let message = data.message;
    let error = data.error;

    if (typeof orderRef !== "string") {
      console.error("Server response is lacking a orderRef");
      return { error: "bankid-rfa5" };
    }
    if (typeof autoStartToken !== "string") {
      console.error("Server response is lacking a autoStartToken");
      return { error: "bankid-rfa5" };
    }
    if (typeof qrAuthCode !== "string") {
      console.error("Server response is lacking a qrAuthCode");
      return { error: "bankid-rfa5" };
    }

    if (typeof message !== "undefined" && typeof message !== "string")
      message = undefined;
    if (typeof error !== "undefined" && typeof error !== "string")
      error = undefined;

    return {
      message: message && "bankid-" + message,
      error: error && "bankid-" + error,
      token: orderRef,
      autoStartToken,
      qrCode: qrAuthCode,
    };
  } else if (res.status === 503) {
    return {
      error: "didNotWork",
    };
  }
  console.warn("Unknown /bankid/start response", res);
  return {
    error: "didNotWork",
  };
}

async function poll(
  abort: AbortSignal,
  token: string,
): Promise<{
  error?: string | undefined;
  message?: string | undefined;
  qrCode?: string | undefined;
  loginResult?: Record<string, unknown> | undefined;
  expired?: boolean | undefined;
}> {
  const body = new URLSearchParams();
  body.append("ref", token);
  const res = await fetch("/bankid/poll", {
    method: "POST",
    signal: abort,
    body,
  });
  const data = await res.json();

  if (res.status === 200) {
    const qrAuthCode = data.qrAuthCode;
    const rfa = hintCodeToRFA(data.status, data.hintCode);
    const error = rfa.error;
    if (data.loginToken) {
      return {
        loginResult: data,
      };
    }

    if (typeof qrAuthCode !== "undefined" && typeof qrAuthCode !== "string") {
      console.error("Invalid qrAuthCode received from server", qrAuthCode);
      return { error: "bankid-rfa5" };
    }

    return {
      message: rfa.message && "bankid-" + rfa.message,
      error: error && "bankid-" + error,
      qrCode: qrAuthCode,
      expired: rfa.expired,
    };
  } else if (res.status === 503) {
    return {};
  } else if (res.status === 400) {
    const { error, error_description } = data;

    if (
      error === "unauthorized_user" &&
      error_description === "The provided credentials are disabled"
    ) {
      return {
        error: "yourCredentialsAreDisabled",
        qrCode: undefined,
        message: undefined,
      };
    }
  }
  console.warn("Unknown /bankid/poll response", res);
  return {};
}
