import React from "react";
import { FormattedMessage } from "react-intl";
import * as QRCode from "qrcode";
import useBankID from "./use-bankid";
import { useAsync } from "react-async-hook";
import { handleLoginResult } from "../../scripts/auth-helper";
import { useAsyncCallback } from "react-async-hook";
import { captureException } from "@sentry/core";

const qrSize = 370;

const QrCode = ({ dataUrl }: { dataUrl: string }) =>
  dataUrl.length > 0 ? (
    <img width={qrSize} height={qrSize} src={dataUrl} />
  ) : (
    <></>
  );

interface Props {
  inviteToken?: string;
  onComplete?: () => void;
}

const BankIDLogin = (p: Props) => {
  const {
    qrCode,
    launchUrl,
    loading,
    message,
    error,
    loginResult,
    expired,
    reset,
  } = useBankID({
    inviteToken: p.inviteToken,
  });

  const qrGeneration = useAsync(
    async () => {
      try {
        if (qrCode) {
          return await QRCode.toDataURL(qrCode, {
            errorCorrectionLevel: "L",
            width: qrSize,
          });
        }
      } catch (ex) {
        captureException(ex);
        console.error(
          "BankID QR code image cannot be generated",
          qrGeneration.error,
        );
        throw ex;
      }
    },
    [qrCode],
    {
      setLoading: (state) => ({ ...state, loading: true }),
    },
  );
  const qrUrl = qrGeneration.result;

  const handleLogin = useAsyncCallback(
    () => loginResult && handleLoginResult(loginResult),
  );

  React.useEffect(() => {
    if (loginResult) {
      handleLogin.execute();
      p.onComplete?.();
    }
  }, [p.onComplete, loginResult]);

  if (handleLogin.loading) return <Spin />;

  return (
    <div className="block">
      <h1 className="title is-1 has-text-centered">
        <FormattedMessage id="logInWithBankID" />
      </h1>
      {error && (
        <div className="notification is-danger is-light has-text-weight-bold has-text-centered">
          <FormattedMessage id={error} />
        </div>
      )}
      {message || qrUrl ? (
        <p className="is-size-5 py-3 has-text-centered">
          <FormattedMessage id={message || "bankIDInstruction"} />
        </p>
      ) : (
        <></>
      )}
      <div className="p-2" />
      {qrUrl ? (
        <>
          {expired ? (
            <button
              className="login-button is-primary is-medium"
              onClick={() => {
                reset();
              }}
            >
              <FormattedMessage id="getNewQRCode" />
            </button>
          ) : (
            <>
              <div className="has-background-white p-2 py-3 mt-3 mb-5 is-flex is-flex-direction-column is-align-items-center">
                <QrCode dataUrl={qrUrl} />
              </div>
              {launchUrl && (
                <a
                  className="login-button is-outlined is-white is-medium"
                  href={launchUrl}
                >
                  <FormattedMessage id="openBankIDApp" />
                </a>
              )}
            </>
          )}
        </>
      ) : (
        loading && <Spin />
      )}
    </div>
  );
};

function Spin() {
  return (
    <div className="py-6 mb-6">
      <div className="is-flex is-justify-content-center">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      </div>
    </div>
  );
}

export default BankIDLogin;
